import Link from '@components/Link';
import type { SideMenuNavigationFragment } from '@server/gql/graphql';
import type { Maybe } from 'graphql/jsutils/Maybe';

import { cn } from '@/lib/utils';
import { getActiveLinkStyle } from '@/utils/common';
// import { SubLinkItem } from './SubLinkItem';

export default function SideMenuNavigation({
    navigation,
    // market,
    locale,
    slug,
}: {
    navigation: Maybe<SideMenuNavigationFragment>;
    // market: string;
    locale: string | undefined;
    slug: string;
}) {
    return (
        <div>
            <h3 className="text-base font-bold">{navigation?.title}</h3>
            <ul className="mt-2 flex flex-col space-y-1.5">
                {navigation?.sublinksCollection?.items?.map((link) => {
                    const linkStyle = getActiveLinkStyle(
                        link?.internalLink?.slug ?? '',
                        slug,
                    );
                    // const hasSubLinks =
                    //     !!link?.sublinksCollection?.items?.length;
                    // if (hasSubLinks) {
                    //     return (
                    //         <SubLinkItem
                    //             key={link?.sys?.id}
                    //             link={link}
                    //             locale={locale}
                    //             market={market}
                    //             slug={slug}
                    //         />
                    //     );
                    // }
                    return (
                        <li className="text-sm" key={link?.sys?.id}>
                            <Link
                                className={cn(linkStyle, 'hover:underline')}
                                locale={locale}
                                href={link?.internalLink?.slug ?? '/'}>
                                {link?.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
