import { ArrowIcon } from '@ngg/icons';
import type {
    ExceptionalOpeningHoursDay,
    OpeningHoursDay,
    SideMenuStoreInfoFragment,
} from '@server/gql/graphql';
import Link from 'next/link';
import type { Dictionary } from 'types/common';

import { cn } from '@/lib/utils';
import {
    getIrregularOpeningHoursDisplayData,
    getOpeningHoursDisplayData,
    getPhoneNumberForLink,
} from '@/utils/common';

export const StoreInfo = ({
    storeData,
    dictionary,
    className,
    locale,
}: {
    storeData?: SideMenuStoreInfoFragment;
    dictionary: Dictionary;
    className: string;
    locale: string | undefined;
}) => {
    const openingHoursData = storeData?.openingHoursCollection
        ?.items as OpeningHoursDay[];
    const irregularOpeningHours = storeData?.exceptionalOpeningHoursCollection
        ?.items as ExceptionalOpeningHoursDay[] | undefined;

    const irregularDisplayData = irregularOpeningHours
        ? getIrregularOpeningHoursDisplayData(irregularOpeningHours, dictionary)
        : null;
    const displayData = getOpeningHoursDisplayData(openingHoursData);
    return (
        <div className={cn('text-xs text-grey-300', className)}>
            <div className="space-y-4">
                <div className="flex flex-col">
                    <h3 className="font-medium uppercase">
                        {storeData?.title}
                    </h3>
                    {storeData?.type === 'Online' && storeData?.email ? (
                        <a
                            className="hover:underline"
                            href={`mailto:${storeData.email}`}>
                            {storeData.email}
                        </a>
                    ) : null}
                    {storeData?.phoneNumber ? (
                        <a
                            className="hover:underline"
                            href={`tel:${getPhoneNumberForLink(
                                storeData?.phoneNumber,
                            )}`}>
                            {storeData.phoneNumber}
                        </a>
                    ) : null}
                </div>
                {displayData?.length ? (
                    <div>
                        <h3 className="font-medium uppercase">
                            {dictionary?.openingHoursServiceTeam}
                        </h3>
                        {displayData.map((item) => (
                            <p
                                key={
                                    item?.text
                                }>{`${item?.text}: ${item?.time}`}</p>
                        ))}
                    </div>
                ) : null}
                {irregularDisplayData?.length ? (
                    <div>
                        <h3 className="mt-4 font-medium uppercase">
                            {dictionary?.irregularOpeningHours}
                        </h3>
                        {irregularDisplayData.map((item) => (
                            <p
                                key={
                                    item?.text
                                }>{`${item?.text}: ${item?.time}`}</p>
                        ))}
                    </div>
                ) : null}
                {storeData?.storePage?.slug && storeData?.storePageLinkText && (
                    <Link
                        locale={locale}
                        className="group inline-flex items-center underline"
                        href={storeData?.storePage?.slug}>
                        {storeData?.storePageLinkText}
                        <ArrowIcon className="text-base text-black opacity-0 transition-[opacity,transform] group-hover:translate-x-0.5 group-hover:opacity-100 supports-[hover]:opacity-0" />
                    </Link>
                )}
            </div>
        </div>
    );
};
