export const AccountButton = ({
    buttonText,
    onClick,
}: {
    buttonText: string;
    onClick: () => void;
}) => (
    <button
        onClick={onClick}
        className="grow pl-4 text-left text-sm underline-offset-2 hover:underline md:mt-1.5"
        type="button">
        {buttonText}
    </button>
);
