'use client';

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Carousel } from '@ngg/components';
import { ArrowLeftIcon, ArrowRightIcon } from '@ngg/icons';
import type { RefObject } from 'react';
import { Children, useEffect, useRef, useState } from 'react';

import { cn } from '@/lib/utils';
import useWindowSize from '@/utils/hooks/useWindowSize';

// Custom carousel component with square dots to navigate between slides on mobile.

const getItems = (ref: RefObject<HTMLDivElement>) => {
    if (!ref.current) return 0;

    const itemWidth = ref?.current ? ref.current?.children[0]?.clientWidth : 0;
    const containerWidth = ref?.current ? ref?.current?.clientWidth : 0;
    const items =
        containerWidth && itemWidth
            ? Math.floor(containerWidth / itemWidth)
            : 0;
    return items;
};

const MobilePagination = ({
    sliderRef,
    activeIndex,
    className,
    activeDotStyle,
}: {
    activeIndex: number;
    sliderRef: RefObject<HTMLDivElement>;
    className?: string;
    activeDotStyle: string;
}) => {
    const [dots, setDots] = useState<number[]>([]);
    const visibleSlides = getItems(sliderRef) ?? 1; // differ depending on screen size.
    const { width } = useWindowSize();

    useEffect(() => {
        if (sliderRef.current) {
            setDots([
                ...Array.from(
                    { length: sliderRef?.current?.childElementCount ?? 0 },
                    (v, i) => i,
                ),
            ]);
        }
    }, [visibleSlides, sliderRef, width]);

    const handleOnClick = (index: number) => {
        const currentChild = sliderRef.current?.children[
            visibleSlides * index
        ] as HTMLDivElement;
        sliderRef?.current?.scrollTo({
            left: currentChild?.offsetLeft,
            behavior: 'smooth',
        });
    };

    return (
        <div
            className={cn(
                'mt-5 flex w-full justify-center lg:hidden',
                className ?? '',
            )}>
            <div className="flex items-center">
                {dots.map((item, i) => (
                    <button
                        type="button"
                        onClick={() => handleOnClick(i)}
                        key={item}
                        className={cn('relative p-1.5')}
                        aria-label={`carousel button ${i + 1}`}>
                        <span
                            className={cn(
                                'block h-2 w-2',
                                activeIndex === i
                                    ? activeDotStyle
                                    : 'bg-grey-300',
                            )}
                        />
                    </button>
                ))}
            </div>
        </div>
    );
};

const PrevButton = ({
    isDisabled,
    className,
    ...props
}: React.HTMLAttributes<HTMLButtonElement> & { isDisabled: boolean }) => (
    <div
        className={cn(
            'pointer-events-none absolute left-0 top-0 z-10 flex items-center',
            className,
        )}>
        <button
            type="button"
            className={cn(
                'pointer-events-auto bg-grey-150 text-[20px]',
                isDisabled ? 'opacity-50' : 'hover:bg-grey-200',
            )}
            {...props}>
            <ArrowLeftIcon />
        </button>
    </div>
);

const NextButton = ({
    isDisabled,
    className,
    ...props
}: React.HTMLAttributes<HTMLButtonElement> & { isDisabled: boolean }) => (
    <div
        className={cn(
            'pointer-events-none absolute right-0 top-0 z-10 flex items-center justify-end',
            className,
        )}>
        <button
            type="button"
            className={cn(
                'pointer-events-auto bg-grey-150 text-[20px]',
                isDisabled ? 'opacity-50' : 'hover:bg-grey-200',
            )}
            {...props}>
            <ArrowRightIcon />
        </button>
    </div>
);

const CarouselDotsNavigation = ({
    children,
    classNames,
    hideNavigation = true,
    firstItemWhite = false,
}: {
    // TODO: fix ngg components
    children: any;
    classNames?: {
        wrapper?: string;
        slider?: string;
        navigation?: string;
        mobilePagination?: string;
        nextButton?: string;
        prevButton?: string;
    };
    hideNavigation?: boolean;
    firstItemWhite?: boolean;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [nextButtonVisibility, setNextButtonVisibility] = useState(false);
    const currentChild = ref.current?.children[activeIndex] as HTMLDivElement;
    const whiteText = currentChild
        ?.querySelector('h2')
        ?.classList.contains('text-white');
    const [activeDotStyle, setActiveDotStyle] = useState(
        firstItemWhite ? 'bg-white' : 'bg-black',
    );

    /* TODO: how do we navigate? */
    const prevSlide = () => {
        if (!ref.current) return;

        const visibleSlides = getItems(ref) ?? 1;
        const newIndex = Math.max(0, activeIndex - visibleSlides);
        setActiveIndex(newIndex);
        const newChild = ref.current?.children[newIndex] as HTMLDivElement;
        ref?.current?.scrollTo({
            left: newChild?.offsetLeft,
            behavior: 'smooth',
        });
        setNextButtonVisibility(
            Math.max(0, activeIndex - visibleSlides) <
                ref.current.children.length - visibleSlides,
        );
    };

    const nextSlide = () => {
        if (!ref.current) return;
        const visibleSlides = getItems(ref) ?? 1;
        const newIndex = Math.min(
            activeIndex + visibleSlides,
            (ref.current?.children.length || 0) - visibleSlides,
        );
        setActiveIndex(newIndex);
        const newChild = ref.current?.children[newIndex] as HTMLDivElement;
        ref?.current?.scrollTo({
            left: newChild?.offsetLeft,
            behavior: 'smooth',
        });
        setNextButtonVisibility(
            Math.min(activeIndex + visibleSlides, ref.current.children.length) <
                ref.current.children.length - visibleSlides,
        );
    };

    useEffect(() => {
        if (!ref.current) return undefined;

        const setSlideItem = (entry: IntersectionObserverEntry) => {
            const target = entry.target as HTMLElement;

            // Going forwards
            if (
                entry.boundingClientRect.left < 0 &&
                entry.intersectionRatio === 0
            ) {
                setActiveIndex(+target.dataset.index! + 1);
                return;
            }

            // Going backwards.
            if (entry.boundingClientRect.left < 0 && entry.isIntersecting) {
                setActiveIndex(+target.dataset.index!);
            }
        };

        const observer = new IntersectionObserver(
            ([entry]) => setSlideItem(entry),
            {
                root: ref.current,
                rootMargin: '0px',
                threshold: [0],
            },
        );

        Array.from(ref.current.children).forEach((child) => {
            observer.observe(child);
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (ref.current) {
            setActiveDotStyle(whiteText ? 'bg-white' : 'bg-black');
        }
    }, [whiteText]);

    useEffect(() => {
        const count = Children.count(children);
        const visible = getItems(ref);
        setNextButtonVisibility(count > visible);
    }, [children]);

    return (
        <Carousel
            ref={ref}
            classNames={classNames}
            hideNavigation={hideNavigation}
            PreviousButton={() =>
                PrevButton({
                    onClick: prevSlide,
                    className: classNames?.prevButton,
                    isDisabled: activeIndex === 0,
                })
            }
            NextButton={() =>
                NextButton({
                    onClick: nextSlide,
                    className: classNames?.nextButton,
                    isDisabled: !nextButtonVisibility,
                })
            }
            Pagination={(props) =>
                MobilePagination({
                    activeIndex,
                    className: classNames?.mobilePagination,
                    activeDotStyle,
                    ...props,
                })
            }>
            {children}
        </Carousel>
    );
};
export default CarouselDotsNavigation;
