'use client';

import { useSiteInfo } from '@context/siteInfoContext';
import { Popover } from '@headlessui/react';
import { ArrowRightIcon } from '@ngg/icons';
import type {
    AccountLayoutNavigationFragment,
    Maybe,
    SideMenuNavigationFragment,
    SideMenuStoreInfoFragment,
} from '@server/gql/graphql';
import { useMemo } from 'react';

import { cn } from '@/lib/utils';

import { MenuItems } from './MenuItems';

export const MobileMenu = ({
    navigations,
    storeData,
    slug,
    locale,
    hideNavigation,
    showAccountButton,
    className,
}: {
    navigations?:
        | Maybe<SideMenuNavigationFragment | AccountLayoutNavigationFragment>[]
        | null;
    storeData?: Maybe<SideMenuStoreInfoFragment>;
    slug: string;
    locale: string | undefined;
    showAccountButton?: boolean;
    hideNavigation?: boolean;
    className?: string;
}) => {
    const { globalDictionary } = useSiteInfo();
    const active = useMemo(() => {
        let output = '';
        let parent = '';
        if (!navigations?.length) return { output, parent };
        for (let i = 0; i < navigations?.length; i += 1) {
            const navigation = navigations[i];
            if (navigation?.sublinksCollection?.items.length) {
                for (
                    let j = 0;
                    j < navigation?.sublinksCollection?.items.length;
                    j += 1
                ) {
                    const item = navigation.sublinksCollection.items[j];
                    if (item?.internalLink?.slug === slug) {
                        output = item.title ?? '';
                    }
                    parent = navigation.title ?? '';
                }
            }
        }
        return { output, parent };
    }, [slug, navigations]);

    if (hideNavigation) return null;

    return (
        <Popover as="div" className={cn('p-3 lg:hidden', className)}>
            <Popover.Button
                type="button"
                className="flex w-full items-center justify-between border border-black bg-transparent px-3 py-2">
                {active?.output || active?.parent}
                <ArrowRightIcon className="text-lg" />
            </Popover.Button>

            <Popover.Overlay className="fixed inset-0 z-20 bg-black/70 lg:hidden" />

            <MenuItems
                slug={slug}
                locale={locale}
                storeData={storeData}
                dictionary={globalDictionary}
                navigations={navigations}
                showAccountButton={showAccountButton}
            />
        </Popover>
    );
};
