'use client';

import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

function ScrollIndicator({
    slides,
    percentage,
}: {
    slides: number;
    percentage: number;
}) {
    const thumbSize = useMemo(
        () => 150 / (slides === 1 ? 2 : slides),
        [slides],
    );
    const left = (150 - thumbSize) * ((percentage || 0) / 100);
    return (
        <div
            className={`relative mx-auto mt-6 h-[3px] w-[140px] bg-neutral-200 transition-opacity lg:hidden ${
                slides > 0 ? 'opacity-1' : 'opacity-0'
            }`}>
            <div
                className="absolute h-full bg-black"
                style={{
                    left: !Number.isNaN(left) ? left : 0,
                    width:
                        !Number.isNaN(thumbSize) && Number.isFinite(thumbSize)
                            ? thumbSize
                            : 0,
                }}
            />
        </div>
    );
}

export default function Slider({
    children,
    className,
    hidePagination = false,
    paginationClassname,
}: {
    children: ReactNode;
    className?: string;
    paginationClassname?: string;
    hidePagination?: boolean;
}) {
    const ref = useRef<HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [scrollPercentage, setScrollPercentage] = useState<number>(0);
    const [slides, setSlides] = useState<number>(0);

    const previous = useCallback(() => {
        const { current } = ref;
        if (current && activeIndex > 0) {
            setActiveIndex((prev) => prev - 1);
            current.scrollTo({
                left:
                    Math.max(current.scrollLeft - current.clientWidth, 0) ?? 0,
                behavior: 'smooth',
            });
        }
    }, [activeIndex, ref]);

    const next = useCallback(() => {
        const { current } = ref;
        if (current && activeIndex < slides) {
            setActiveIndex((prev) => prev + 1);
            current.scrollTo({
                left: current.scrollLeft + current.clientWidth,
                behavior: 'smooth',
            });
        }
    }, [activeIndex, slides, ref]);

    const getSlides = useCallback(
        () =>
            ref.current &&
            setSlides(
                Math.round(
                    (ref.current.scrollWidth / ref.current.clientWidth) * 2,
                ) / 2,
            ),
        [ref],
    );

    const getTopPosition = useCallback(
        () =>
            ref.current &&
            setScrollPercentage(
                Math.ceil(
                    (100 * ref.current.scrollLeft) /
                        (ref.current.scrollWidth - ref.current.clientWidth),
                ),
            ),
        [ref],
    );

    useEffect(() => {
        getSlides();
        getTopPosition();
        const { current } = ref;
        if (current) current.addEventListener('scroll', getTopPosition);
        window.addEventListener('resize', getSlides);
        return () => {
            window?.removeEventListener('scroll', getSlides);
            if (current) current?.removeEventListener('scroll', getTopPosition);
        };
    }, [ref, getSlides, getTopPosition]);

    return (
        <div className={`relative overflow-hidden ${className}`}>
            {!hidePagination && slides > 1 && (
                <button
                    onClick={previous}
                    type="button"
                    className={`absolute left-1 top-1/4 z-10 bg-white p-2 ${
                        activeIndex === 0 ? 'hidden' : 'md:flex'
                    } ${paginationClassname}`}>
                    <i className="ri-arrow-left-line ri-xl" />
                </button>
            )}
            <div
                className="w-full snap-x snap-mandatory overflow-x-scroll scrollbar-none"
                ref={ref}>
                <div className="flex w-full">{children}</div>
            </div>
            {!hidePagination && slides > 1 && (
                <button
                    type="button"
                    onClick={next}
                    className={`absolute right-1 top-1/4 z-10 hidden bg-white p-2 ${paginationClassname} ${
                        Math.floor(slides) - 1 === activeIndex
                            ? 'hidden'
                            : 'md:flex'
                    }`}>
                    <i className="ri-arrow-right-line ri-xl" />
                </button>
            )}
            <ScrollIndicator percentage={scrollPercentage} slides={slides} />
        </div>
    );
}
