import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/src/app/_blocks/Accordion/Accordion.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_blocks/BrandBanner/BrandBanner.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_blocks/Countdown/Countdown.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_blocks/EditorialGallery/EditorialGallery.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_blocks/Store/Store.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/Carousel/Carousel.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/Carousel/CarouselDotsNavigation.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ContentfulPicture/ContentfulPicture.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ContentfulVideo/ContentfulVideo.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/Frame/Frame.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ImboxButton/ImboxButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/Link/Link.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/SideMenu/DesktopMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/SideMenu/MobileMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ui/Carousel/Carousel.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ui/Dialog/Dialog.ts");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ui/Heading/Heading.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_components/ui/Input/Input.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/bookMeeting.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/claims.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/contact.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/formPopup.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/matchPrice.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/newCustomer.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/_forms/return.tsx");
import(/* webpackMode: "eager" */ "/app/src/utils/imageLoader.ts")