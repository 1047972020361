'use client';

import { useAuth } from '@context/authContext';
import { Popover, Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@ngg/icons';
import type {
    AccountLayoutNavigationFragment,
    Maybe,
    SideMenuNavigationFragment,
    SideMenuNavigationLinkFragment,
    SideMenuStoreInfoFragment,
} from '@server/gql/graphql';
import { Fragment, useState } from 'react';
import type { Dictionary } from 'types/common';

import { cn } from '@/lib/utils';

import { AccountButton } from './AccountButton';
import { ListItem } from './ListItem';
import { StoreInfo } from './StoreInfo';

export const MenuItems = ({
    navigations,
    storeData,
    dictionary,
    slug,
    locale,
    showAccountButton,
}: {
    navigations?:
        | Maybe<SideMenuNavigationFragment | AccountLayoutNavigationFragment>[]
        | null;
    storeData?: Maybe<SideMenuStoreInfoFragment>;
    dictionary: Dictionary;
    slug: string;
    locale: string | undefined;
    showAccountButton?: boolean;
}) => {
    const { logout } = useAuth();
    const [item, setItem] = useState<SideMenuNavigationLinkFragment | null>(
        null,
    );

    const handleClickBack = () => {
        setItem(null);
    };

    const handleAccount = () => {
        logout().catch(() => null);
    };

    return (
        <Transition
            as={Fragment}
            enter="transition-transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            beforeEnter={() => {
                setItem(null);
                document
                    ?.querySelector('body')
                    ?.classList.add('overflow-hidden');
            }}
            afterLeave={() =>
                document
                    ?.querySelector('body')
                    ?.classList.remove('overflow-hidden')
            }>
            <Popover.Panel
                as="nav"
                className="fixed inset-0 z-30 w-4/5 overflow-y-auto bg-white text-sm lg:hidden">
                {({ close }) => (
                    <>
                        <div
                            className={cn(
                                'flex w-full items-center justify-between bg-white px-4',
                                item ? 'h-14' : 'h-10',
                            )}>
                            {item ? (
                                <button
                                    className="flex items-center text-base"
                                    type="button"
                                    onClick={handleClickBack}>
                                    <ArrowLeftIcon />
                                    {dictionary.back}
                                </button>
                            ) : null}
                            <button
                                type="button"
                                className="ml-auto text-xs font-medium uppercase underline"
                                onClick={() => close()}>
                                {dictionary.close}
                            </button>
                        </div>

                        {!item && navigations
                            ? navigations.map((navigation) => (
                                  <div
                                      key={navigation?.sys?.id}
                                      className="border-t border-grey-150">
                                      <h3 className="flex h-10 items-center px-4 font-bold">
                                          {navigation?.title}
                                      </h3>
                                      <ul>
                                          {navigation?.sublinksCollection?.items?.map(
                                              (link) => {
                                                  if (!link) return null;
                                                  return (
                                                      <ListItem
                                                          slug={slug}
                                                          key={link?.sys?.id}
                                                          link={link}
                                                          locale={locale}
                                                          onClose={() =>
                                                              close()
                                                          }
                                                      />
                                                  );
                                              },
                                          )}
                                          {showAccountButton ? (
                                              <li className="flex h-10 w-full items-center justify-between gap-4 text-sm">
                                                  <AccountButton
                                                      buttonText={
                                                          dictionary?.signOut
                                                      }
                                                      onClick={() => {
                                                          handleAccount();
                                                          close();
                                                      }}
                                                  />
                                              </li>
                                          ) : null}
                                      </ul>
                                  </div>
                              ))
                            : null}

                        {storeData ? (
                            <StoreInfo
                                locale={locale}
                                storeData={storeData}
                                dictionary={dictionary}
                                className="mb-6 px-4 pt-4 lg:hidden"
                            />
                        ) : null}
                    </>
                )}
            </Popover.Panel>
        </Transition>
    );
};
