'use client';

import Link from '@components/Link';
import type { SideMenuNavigationLinkFragment } from '@server/gql/graphql';
import type { ReactNode } from 'react';

import { cn } from '@/lib/utils';

export const ListItem = ({
    link,
    onClose,
    NextLevelButton,
    slug,
    locale,
}: {
    link: SideMenuNavigationLinkFragment;
    onClose?: () => void;
    NextLevelButton?: () => ReactNode;
    slug: string;
    locale: string | undefined;
}) => (
    <li className="flex h-10 w-full items-center justify-between gap-4 px-4 text-sm">
        <Link
            onClick={onClose}
            className={cn(
                'grow truncate underline-offset-2 hover:underline',
                link?.internalLink?.slug?.endsWith(slug) &&
                    'font-medium underline',
            )}
            locale={locale}
            href={link?.internalLink?.slug ?? '/'}>
            {link?.title}
        </Link>
        {NextLevelButton && NextLevelButton()}
    </li>
);
