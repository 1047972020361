declare global {
    interface Window {
        _imbox: any;
    }
}

export function openImboxForm(id?: number) {
    const imbox = ensureImbox();

    const params = ['openForm', id].filter(Boolean);

    imbox.push(params);
}

function ensureImbox(): any[] {
    if (typeof window?._imbox === 'undefined') {
        window._imbox = [];
    }

    return window._imbox;
}
