'use client';

import { Button } from '@components/ui';
import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';

import { openImboxForm } from '@/utils/imbox';

type ImboxButtonProps = ComponentPropsWithoutRef<'button'>;

export default function ImboxButton({ children, ...props }: ImboxButtonProps) {
    return (
        <Button
            className="btn-primary"
            {...props}
            onClick={() => openImboxForm()}>
            {children}
        </Button>
    );
}
